<template>
  <div class="animated fadeIn">
    <b-row align-h="between">
      <b-col lg="6">
        <b-col>
          <b-button-group>
            <b-button variant="outline-dark" title="Create" @click="addItem()">
              <font-awesome-icon icon="plus" /> Create
            </b-button>
          </b-button-group>
        </b-col>
      </b-col>
      <b-col lg="1">
        <font-awesome-icon
          class="help"
          size="2x"
          icon="question-circle"
          @click="showHelp()"
        />
      </b-col>
    </b-row>
    <hr />

    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="afterFilter"></div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-dark btn-sm"
            @click="viewContent(props.row)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </button>
          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
          >
            <font-awesome-icon icon="eye" />
          </button>
          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
            v-if="!(props.row.Reviewed == 'Yes')"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
            v-if="!(props.row.Reviewed == 'Yes')"
          >
            <font-awesome-icon icon="trash" />
          </button>

          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>

      <span slot-scope="props" slot="child_row">
        <files-container
          ref="files"
          :module-id="$route.meta.module.id"
          :entity-id="props.row.ID"
        />
      </span>
    </table-custom>

    <b-modal
      ref="help-modal"
      centered
      scrollable
      hide-footer
      size="lg"
      title="Make help section"
    >
      <b-card>
        <b-card-body v-html="helpModal.htmlContent" />
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="closeHelp()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
    <b-modal
      ref="preview-receipt-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <b-row>
            <b-col lg="6">
              <strong>User name: </strong> {{ previewModal.userName }}
            </b-col>
            <b-col lg="6">
              <strong>Submission Date: </strong>
              {{ previewModal.submissionDate }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <strong>Charge Date: </strong> {{ previewModal.chargeDate }}
            </b-col>
            <b-col lg="6">
              <strong>Account Name: </strong> {{ previewModal.accountName }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <strong>Currency: </strong> {{ previewModal.currency }}
            </b-col>
            <b-col lg="6">
              <strong>Rate: </strong> {{ previewModal.rate }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <strong>Amount: </strong> {{ previewModal.currencySymbol
              }}{{ previewModal.amount }}
            </b-col>

            <b-col lg="6">
              <strong>USD Amount: </strong> ${{ previewModal.usdAmount }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Notes: </strong>
              {{ previewModal.notes }}
            </b-col>
            <b-col> <strong>Name: </strong> {{ previewModal.name }} </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Files: </strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="(f, index) in previewModal.files"
              :key="`rec-${index}`"
            >
              <img
                v-if="f.type == 'image'"
                v-auth-image="f.url"
                class="receipt-thumbnail"
              />
              <div v-if="f.type !== 'image'">
                {{ f.name }}
                <b-button
                  class="m-1"
                  variant="success"
                  size="sm"
                  @click="downloadItem(f)"
                >
                  <font-awesome-icon icon="download" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          v-if="!previewModal.reviewed && previewModal.isReviewer"
          variant="warning"
          class="m-1"
          @click="setReceiptReviewed()"
        >
          <font-awesome-icon icon="signature" /> Mark as Reviewed
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import FilesContainer from "@/components/FilesContainer";
import TableCustom from "@/components/TableCustom";

import helpContent from "./help-content.js";

export default {
  name: "ReceiptsTable",
  props: {
    mode: {
      type: String,
      default: "all-receipts"
    }
  },
  components: {
    TableCustom,
    FilesContainer
  },
  data: function() {
    return {
      rawData: {},
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          columns: [
            "ID",
            "User Name",
            "Category",
            "Submission Date",
            "Charge Date",
            "Amount",
            "Currency",
            "Rate",
            "USD Amount",
            "Account Name",
            "Name",
            "Reviewed",
            "Actions"
          ],
          filterable: [
            "ID",
            "User Name",
            "Category",
            "Submission Date",
            "Charge Date",
            "Amount",
            "Currency",
            "Rate",
            "USD Amount",
            "Account Name",
            "Name",
            "Reviewed"
          ],
          //showActions: true,
          perPage: 50,
          showCustomActions: true,
          showChildRows: true
        }
      },

      previewModal: {
        id: "",
        title: "",
        userName: "",
        submissionDate: "",
        chargeDate: "",
        accountName: "",
        name: "",
        currency: "",
        currencySymbol: "",
        amount: "",
        rate: "",
        usdAmount: "",
        notes: "",
        reviewed: false
      },
      helpModal: {
        htmlContent: helpContent
      }
    };
  },
  computed: {},
  mounted() {
    // this.getData();
  },
  methods: {
    showHelp() {
      this.$refs["help-modal"].show();
    },
    closeHelp: function() {
      this.$refs["help-modal"].hide();
    },
    downloadItem: function(file) {
      axios({
        url: file.url,
        method: "GET",
        responseType: "blob" // important
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.data.type
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          //const contentDisposition = response.headers['content-disposition'];
          let fileName = file.name;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(response => {
          console.log(response);

          this.$form.makeToastError(response.message);
        });
    },
    toggleFiles(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    },
    async getData(payload) {
      this.dataTable.isLoading = true;

      let url = this.mode == "my-receipts" ? "receipts/my" : "receipts";

      let response = [];

      try {
        response = await this.$api.post(url, payload);

        this.rawData = response;

        this.dataTable.isLoading = false;

        this.dataTable.dataSet = this.rawData;
      } catch (error) {
        console.log(error);

        this.dataTable.isLoading = false;

        this.$form.msgBoxOk("Error occured");
      }

      this.$emit("loaded", this.dataTable.dataSet.length);
    },

    addItem: function() {
      this.$router.push({
        name: "Receipt submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Receipt submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Receipt submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let receipt = this.dataTable.dataSet.find(item => item.ID == id);

      let confirm = await this.$form.showConfirmation(
        `Receipt #${receipt.ID} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        item => item.ID !== id
      );

      let self = this;

      this.$api
        .delete(`receipts/${receipt.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    setReceiptReviewed() {
      let self = this;
      let id = this.previewModal.id;

      this.$api
        .put(`receipts/${id}`, {
          id: id,
          reviewed: true
        })
        .then(response => {
          self.previewModal.reviewed = true;
          self.getData();

          self.$form.makeToastInfo(response.message);
        })
        .catch(error => {
          console.log(error);

          self.$form.makeToastError(error.message);
        });
    },
    closeModal: function() {
      this.$refs["preview-receipt-modal"].hide();
    },
    viewContent(receipt) {
      this.previewModal.id = receipt["ID"];
      this.previewModal.title = `Receipt #${receipt["ID"]}`;
      this.previewModal.userName = receipt["User Name"];
      this.previewModal.submissionDate = receipt["Submission Date"];
      this.previewModal.chargeDate = receipt["Charge Date"];
      this.previewModal.accountName = receipt["Account Name"];
      this.previewModal.name = receipt["Name"];
      this.previewModal.currency = receipt["Currency"];
      this.previewModal.currencySymbol = receipt["currency_symbol"];
      this.previewModal.amount = receipt["Amount"];
      this.previewModal.rate = receipt["Rate"];
      this.previewModal.usdAmount = receipt["USD Amount"];
      this.previewModal.notes = receipt["Notes"];
      this.previewModal.reviewed = receipt["Reviewed"] == "Yes";
      this.previewModal.isReviewer = receipt["is_reviewer"] == "1";

      this.previewModal.files = [];
      let files = receipt["file_ids"] ? receipt["file_ids"].split("|") : [];

      files.forEach(f => {
        let a = f.split(";");
        let file = {};
        file.url = process.env.VUE_APP_API_URL + "/files/" + a[0];
        file.name = a[1];
        file.type = a[2];
        this.previewModal.files.push(file);
      });

      //this.previewModal.files.forEach((f, i, a) => a[i] = `${process.env.VUE_APP_API_URL}/files/${f.id}`)

      this.$refs["preview-receipt-modal"].show();
    }
  },
  watch: {}
};
</script>

<style scoped>
.receipt-thumbnail {
  width: 300px;
  padding: 1em;
}

.help {
  cursor: pointer;
}
</style>
