<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="receipts"
              :show-labels="false"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @async-search="onAsyncSearch"
              @search="getData"
              @change="filterData"
              :load-dictionaries="loadDictionaries"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row v-show="dataTable.visible">
          <b-col>
            <receipts-table
              ref="receipts"
              :mode="mode"
              @loaded="onReceiptsLoad"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";
import ReceiptsTable from "@/views/CompanyCardReceipts/ReceiptsTable";

export default {
  name: "Receipts",
  props: {
    mode: {
      type: String,
      default: "all-receipts"
    }
  },
  components: {
    FilteringPanel,
    ReceiptsTable
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: "select",
            dataType: "string",
            title: "User Name",
            name: "receipt_user_name",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            title: "Category",
            name: "receipt_category",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            title: "Account",
            tooltip: "Account name",
            name: "receipt_account_name",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true,
            async: true,
            loading: false,
            startsWith: false
          },
          {
            type: "select",
            dataType: "string",
            title: "Reviewed",
            name: "receipt_reviewed",
            trackby: "id",
            label: "label",
            options: this.$form.yesNo.options,
            selected: {},
            multiple: true
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "This quarter",
            title: "Submission period",
            name: "receipt_submission_period"
          }
        ]
      },
      dataTable: {
        isLoading: false,
        visible: true
      }
    };
  },
  computed: {},
  created() {
    if (this.mode === "my-receipts") {
      this.filteringPanel.filters = this.filteringPanel.filters.filter(
        f => f.name !== "receipt_user_name"
      );
    }
  },
  mounted() {},
  methods: {
    onAsyncSearch(payload) {
      if (payload.filterName === "account") {
        let accountsFilter = this.filteringPanel.filters.find(
          f => f.name === "receipt_account_name"
        );

        accountsFilter.loading = true;

        this.$api
          .post("dictionaries/accounts", {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false;

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      }
    },
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        if (
          this.filteringPanel.filters.find(f => f.name === "receipt_user_name")
        ) {
          this.$api.get("dictionaries/users").then(response => {
            this.filteringPanel.filters.find(
              f => f.name === "receipt_user_name"
            ).options = [
              ...response.map(u => ({
                id: u.id,
                label: u.full_name
              }))
            ];
          });
        }
      };

      const categories = async () => {
        this.$api.get("dictionaries/receipts/categories").then(response => {
          this.filteringPanel.filters.find(
            f => f.name === "receipt_category"
          ).options = [
            ...response.map(u => ({
              id: u.id,
              label: u.name
            }))
          ];
        });
      };

      Promise.all([users(), categories()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },

    onReceiptsLoad() {
      //this.filteringPanel.filters.find(f => f.name === "reviewed").options = this.$helpers.getDistinctArray(this.$refs['receipts'].rawData, "Reviewed", "Reviewed");
      //this.filterData(this.filteringPanel.selected)
    },
    filterData: function(e) {
      this.filteringPanel.selected = e;

      /*
            if (!this.$refs['receipts'].rawData.length) return;

            let filteredData = this.$refs['receipts'].rawData;

            filteredData = filteredData.filter(i =>
                this.filteringPanel.selected["user_name"] ?
                this.filteringPanel.selected["user_name"].name === i["User Name"] : true
            );

            filteredData = filteredData.filter(i =>
                this.filteringPanel.selected["category"] ?
                this.filteringPanel.selected["category"].name === i["Category"] : true
            );

            filteredData = filteredData.filter(i =>
                this.filteringPanel.selected["reviewed"] ?
                this.filteringPanel.selected["reviewed"].name === i["Reviewed"] : true
            );

            filteredData = filteredData.filter(i =>
                this.filteringPanel.selected["account"] ?
                this.filteringPanel.selected["account"].name === i["Account Name"] : true
            );

            filteredData = filteredData.filter(i =>
                this.filteringPanel.selected["submission-period"] ?
                new Date(i["Submission Date"]) >= new Date(this.filteringPanel.selected["submission-period"].startDate) &&
                new Date(i["Submission Date"]) <= new Date(this.filteringPanel.selected["submission-period"].endDate) : true
            );

            this.$refs['receipts'].dataTable.dataSet = filteredData;
*/
    },
    getData() {
      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        user_name: f.receipt_user_name
          ? f.receipt_user_name.map(i => i.label)
          : [],
        account_name: f.receipt_account_name
          ? f.receipt_account_name.map(i => i.label)
          : [],
        category: f.receipt_category
          ? f.receipt_category.map(i => i.label)
          : [],
        reviewed: f.receipt_reviewed
          ? f.receipt_reviewed.map(i => i.label)
          : [],
        period: f.receipt_submission_period
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["receipts"].getData(payload);
    }
  },
  watch: {}
};
</script>

<style></style>
