const help = `
<p>Dear Holders and Users of company&rsquo;s business credit cards,&nbsp;</p>
<p>Handling your records in the new Credit Cards Receipts System I found out a lot of expenses that were categorized wrong way. I understand that our classification&nbsp; is not perfect and somewhere ambiguous . However we need to follow it to analyze our business activity correctly.</p>
<p>Let me give you some helpful hints regarding the categories of our credit card expenses.</p>
<p>1. <strong>Meal.&nbsp;</strong></p>
<p>There are several categories that describe meal expenses:</p>
<p><strong>Employees&rsquo; meal</strong> (4) included:</p>
<p>- Company lunches (usually ordered by Mark)</p>
<p>- Additional meal purchased by manages for employees (Donuts, etc.)</p>
<p>- Personal lunches for employees who eligible for company meal benefit but weren&rsquo;t involved to company lunch for some reason.</p>
<p>- Meal for meetings &ldquo;manager - employee&rdquo; or for internal company&rsquo;s events.</p>
<p><strong>No any other meal expenses belong for category Employees&rsquo; meal&nbsp;</strong>&nbsp;</p>
<p><strong>Meal in travel</strong> (1) - regular meal during the business trip for traveler only. Business trip should be overnight or exceed regular work day hours.&nbsp;</p>
<p><strong>Domestic meal expenses (f.e. for chicagoans in Chicagoland) cannot be considered as Meal-in- travel</strong></p>
<p><strong>Promotional Events</strong> (5) - Food (and entertainment) events with business-related persons (vendors, distributers, insurers, so on) to discuss business matters. It includes restaurant visits as well as catering for office business meetings</p>
<p><strong>Trade Shows, Festivals, Professional Competitions don&rsquo;t belong to Promotional Event category. This is &ldquo;Trade Shows and Events&rdquo; (will be determined below)</strong></p>
<p>Food expenses can be included also to the categories:</p>
<p><strong>Marketing </strong>(10)<strong> - </strong>if employee visits bar or restaurant for assortment and price monitoring&nbsp;</p>
<p><strong>Professional Supply </strong>(6) - if some food or drinks were purchased for our testing events or cocktail classes</p>
<p><strong>Workshop </strong>(should be added) - food for workshop participants</p>
<p>2. <strong>Supplies</strong></p>
<p><strong>Office Supplies </strong>(3) - all stuff for office, cleaning, lunch room, so on.</p>
<p><strong>Distillery &amp; Warehouse Supplies</strong> (18) - tools, working clothes and shoes, other things for production process</p>
<p><strong>Replacement parts for equipment don&rsquo;t belong for supply category,&nbsp; it&rsquo;s Equipment Repairs (26)</strong></p>
<p><strong>Professional Supplies </strong>(6) - all things used in the professional activity:</p>
<p>- stuff for testing and cocktail events - cups, special bags, cocktail sets, folded tables, table clothes, so on</p>
<p>- food and drink for testing, cocktail, trade shows event - ice, sparkling water, snacks, pizza, pairing food, etc.</p>
<p>- supplies and objects for photo sessions - cameras, lights, food, decoration things.</p>
<p><strong>Promotional Supplies</strong> (11):</p>
<p>- stuff with Company&rsquo;s logo (t-shorts, caps, flasks, etc.),&nbsp;</p>
<p>- gifts for business related persons including food gifts, flowers and Koval production</p>
<p>3. <strong>Trade Shows and Events </strong>(12) - includes only payments for participation (booth rent fee or something like this) and payments to partners for duo tours.</p>
<p><strong>Any other expenses related to events&nbsp; should be classified with another categories. For example: taxi to get event - &ldquo;transportation&rdquo;, ingredients for event - &ldquo;professional supply&rdquo;, so on</strong></p>
<p>4. <strong>Other Categories</strong> - Below you can find full list of the categories applied in our system now. Please, read it carefully and don&rsquo;t hesitate contact me if you have any questions.</p>
<p>Also some new categories will be added to the list soon - production ingredients, workshop, some other</p>
<p><strong>Couple of important notices in addition</strong></p>
<p>The field &ldquo;Receipt Date&rdquo; should contain exact date of receipt,&nbsp; NOT THE DATE WHEN YOU UPLOAD IT</p>
<p>Dear foreign representatives (Fuyuko, Michael, Manuel) and employees who travel abroad - Please, DON'T FORGET to put transactions currency to the &ldquo;Notes&rdquo; field</p>
`;

export default help;
