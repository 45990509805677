var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Create"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Create ")], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "1"
    }
  }, [_c('font-awesome-icon', {
    staticClass: "help",
    attrs: {
      "size": "2x",
      "icon": "question-circle"
    },
    on: {
      "click": function click($event) {
        return _vm.showHelp();
      }
    }
  })], 1)], 1), _c('hr'), _c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-dark btn-sm",
          attrs: {
            "title": "Preview"
          },
          on: {
            "click": function click($event) {
              return _vm.viewContent(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), !(props.row.Reviewed == 'Yes') ? _c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1) : _vm._e(), !(props.row.Reviewed == 'Yes') ? _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e(), _c('button', {
          class: [props.row['FilesCount'] !== '0' ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleFiles(props.row['ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "download"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('files-container', {
          ref: "files",
          attrs: {
            "module-id": _vm.$route.meta.module.id,
            "entity-id": props.row.ID
          }
        })], 1);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  })]), _c('b-modal', {
    ref: "help-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "hide-footer": "",
      "size": "lg",
      "title": "Make help section"
    }
  }, [_c('b-card', [_c('b-card-body', {
    domProps: {
      "innerHTML": _vm._s(_vm.helpModal.htmlContent)
    }
  })], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeHelp();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1), _c('b-modal', {
    ref: "preview-receipt-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewModal.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("User name: ")]), _vm._v(" " + _vm._s(_vm.previewModal.userName) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Submission Date: ")]), _vm._v(" " + _vm._s(_vm.previewModal.submissionDate) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Charge Date: ")]), _vm._v(" " + _vm._s(_vm.previewModal.chargeDate) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Account Name: ")]), _vm._v(" " + _vm._s(_vm.previewModal.accountName) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Currency: ")]), _vm._v(" " + _vm._s(_vm.previewModal.currency) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Rate: ")]), _vm._v(" " + _vm._s(_vm.previewModal.rate) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Amount: ")]), _vm._v(" " + _vm._s(_vm.previewModal.currencySymbol) + _vm._s(_vm.previewModal.amount) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("USD Amount: ")]), _vm._v(" $" + _vm._s(_vm.previewModal.usdAmount) + " ")])], 1), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Notes: ")]), _vm._v(" " + _vm._s(_vm.previewModal.notes) + " ")]), _c('b-col', [_c('strong', [_vm._v("Name: ")]), _vm._v(" " + _vm._s(_vm.previewModal.name) + " ")])], 1), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Files: ")])])], 1), _c('b-row', _vm._l(_vm.previewModal.files, function (f, index) {
    return _c('b-col', {
      key: "rec-".concat(index)
    }, [f.type == 'image' ? _c('img', {
      directives: [{
        name: "auth-image",
        rawName: "v-auth-image",
        value: f.url,
        expression: "f.url"
      }],
      staticClass: "receipt-thumbnail"
    }) : _vm._e(), f.type !== 'image' ? _c('div', [_vm._v(" " + _vm._s(f.name) + " "), _c('b-button', {
      staticClass: "m-1",
      attrs: {
        "variant": "success",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadItem(f);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "download"
      }
    })], 1)], 1) : _vm._e()]);
  }), 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [!_vm.previewModal.reviewed && _vm.previewModal.isReviewer ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.setReceiptReviewed();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "signature"
    }
  }), _vm._v(" Mark as Reviewed ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }